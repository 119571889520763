:export {
  min-width-tablet: 768px;
  max-width-tablet: 1400px; }

.MuiMobileStepper-positionBottom {
  left: auto !important;
  right: auto !important;
  bottom: auto !important; }

.MuiMobileStepper-root {
  background: #FFF !important; }

.text-onboarding {
  font-size: 4vw !important;
  color: #000; }

.icon-button-next {
  font-size: 4vh !important; }

.button-back-onboarding {
  display: none !important; }

.button-next-onboarding {
  border-radius: 3px !important;
  height: 7vh;
  width: 30vw;
  font-size: 4vw !important;
  text-transform: capitalize !important;
  color: #FFF !important;
  position: fixed; }

.MuiMobileStepper-dot {
  width: 5vw !important;
  height: 3vh !important;
  margin: 0px 3px !important; }

@media (max-width: 1400px) and (min-width: 768px) and (orientation: landscape) {
  .MuiMobileStepper-dot {
    width: 3vw !important;
    height: 5vh !important; }
  .text-onboarding {
    font-size: 2vw !important; }
  .button-next-onboarding {
    height: 4vw;
    font-size: 3vw !important; } }
