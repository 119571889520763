body {
  font-family: Roboto; }

p {
  margin: 0 !important; }

.App {
  align-content: center;
  align-items: center;
  text-align: center;
  transition: 1s; }

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none; }

.App-header {
  background-color: #F6F6F6;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #FFF; }

.App-link {
  color: #293370; }
