.MuiSnackbar-anchorOriginBottomCenter {
  bottom: 2vh !important; }

.message {
  display: flex;
  align-items: center; }

.snackbar-success {
  background-color: #43a047 !important; }

.snackbar-info {
  background-color: #90caf9 !important; }

.snackbar-warning {
  background-color: #ffa000 !important; }

.snackbar-error {
  background-color: #d32f2f !important; }

.icon {
  font-size: 20px !important;
  opacity: 0.9;
  margin-right: 1rem; }

.max-width-65 {
  max-width: 65vw; }
