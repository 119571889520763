:export {
  min-width-tablet: 768px;
  max-width-tablet: 1400px; }

.img-etapa-3 {
  margin: 0;
  width: 60vw;
  height: 30vh; }

@media (max-width: 1400px) and (min-width: 768px) and (orientation: landscape) {
  .img-etapa-3 {
    width: 40vw;
    height: 30vh; } }
