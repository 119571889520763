.circularProgress {
  top: 40%;
  right: 35%;
  width: 30vw !important;
  height: 30vw !important;
  position: fixed !important;
  z-index: 999 !important;
  cursor: forbidden !important; }

@media (min-width: 900px) {
  .circularProgress {
    top: 40%;
    right: 50%;
    width: 10vw !important;
    height: 10vw !important; } }
