:export {
  min-width-tablet: 768px;
  max-width-tablet: 1400px; }

.text-situacao {
  font-size: 5vw;
  font-weight: 600 !important;
  color: #4A4A4A; }

.img-center-aprovada {
  width: 70vw; }

.img-center-pendente {
  width: 50vw; }

.img-center-reprovada {
  width: 80vw; }

.img-center {
  width: 70vw; }
