:export {
  min-width-tablet: 768px;
  max-width-tablet: 1400px; }

.img-etapa-1 {
  margin: 0;
  width: 55vw;
  height: 35vh; }

@media (max-width: 1400px) and (min-width: 768px) and (orientation: landscape) {
  .img-etapa-1 {
    margin: 0;
    width: 25vw !important;
    height: 30vh !important; } }
