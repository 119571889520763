.title-fim {
  font-size: 7vw !important;
  font-weight: 900 !important; }

.text-fim {
  color: #4a4a4a;
  font-size: 5vw !important; }

.logo-fim {
  width: 50vw; }

.img-fim {
  width: 70vw;
  height: 30vh; }

@media (min-width: 900px) {
  .title-fim {
    font-size: 3vw !important;
    font-weight: 900 !important; }
  .text-fim {
    color: #4a4a4a;
    font-size: 1.5vw !important; }
  .logo-fim {
    width: 10vw; }
  .img-fim {
    width: 30vw;
    height: 45vh; }
  .ballons {
    background-image: url("/images/fim_background.png");
    background-repeat: no-repeat;
    background-size: 100% 100%; } }
