:export {
  min-width-tablet: 768px;
  max-width-tablet: 1400px; }

.closeIcon {
  float: right !important;
  font-size: 10vw !important;
  color: #BDBDBD; }

.title-termo {
  font-size: 6vw !important;
  font-weight: 900 !important;
  color: #000; }

.text-termo {
  font-size: 3.5vw !important;
  color: #4A4A4A;
  line-height: 1.67 !important; }

@media (max-width: 1400px) and (min-width: 768px) {
  .closeIcon {
    font-size: 7vw !important; }
  .title-termo {
    font-size: 5vw !important; }
  .text-termo {
    font-size: 2.5vw !important; } }

@media (min-width: 900px) {
  .dialog-fullscreen-termo > .MuiDialog-container > .MuiDialog-paperFullScreen {
    width: 70vw !important;
    height: 80% !important;
    max-width: 1280px !important;
    overflow-x: hidden; }
  .title-termo {
    font-size: 2vw !important;
    width: 45vw; }
  .text-termo {
    font-size: 1vw !important;
    width: 45vw; }
  .closeIcon {
    font-size: 3vw !important; }
  #div-faq-dinamico {
    margin-top: 6vh; }
  .circular-progress-termo {
    width: 20vw !important;
    height: 20vw !important;
    margin-top: 20vh !important; } }
