/* General */
:export {
  min-width-tablet: 768px;
  max-width-tablet: 1400px; }

.box {
  text-align: left;
  margin: 20px; }

.box-white {
  background-color: #FFF; }

.box-grey {
  background-color: #F3F3F3; }

.div-height-2000 {
  height: 2000px; }

.div-height-100-important {
  height: 100% !important; }

.div-height-100 {
  height: 100%; }

.div-height-vh100 {
  height: 100vh; }

.div-height-45 {
  height: 45vh; }

.divContent {
  position: relative;
  margin-top: 3vh;
  max-width: 100vw; }

.divFlex {
  display: flex; }

.divLeft {
  text-align: left; }

.max-width-50 {
  max-width: 50vw; }

.divTitle {
  margin-top: 30px; }

.textDanger {
  color: red !important; }

.padding-top-17 {
  padding-top: 1.7vh; }

.subTitle {
  text-align: left;
  color: #9B9B9B; }

.label {
  color: #4A4A4A !important;
  text-align: justify;
  font-weight: 500 !important;
  font-size: 4vw !important;
  letter-spacing: normal !important;
  font-family: 'Roboto'; }

.textField {
  text-align: justify;
  width: 100% !important;
  margin-top: 1vh !important; }

.link-cep {
  cursor: pointer !important;
  font-weight: 500 !important;
  text-decoration: underline !important;
  font-size: 4.5vw !important;
  color: #214bff !important; }

.link-pep {
  cursor: pointer !important;
  font-weight: 500 !important;
  text-decoration: underline !important;
  font-size: 3.5vw !important;
  color: #214bff !important; }

.link-faq {
  cursor: pointer !important;
  font-weight: 500 !important;
  text-decoration: underline !important;
  font-size: 4vw !important;
  color: #214bff !important; }

.link {
  cursor: pointer !important;
  font-weight: bold !important;
  text-decoration: underline !important;
  font-size: 4vw !important;
  color: #6b87ff !important; }

.position-fixed {
  position: fixed; }

.position-relative {
  position: relative; }

.overflow-x-hidden {
  overflow-x: hidden; }

.buttons-bottom {
  position: fixed;
  bottom: 5vh;
  width: 90vw; }

.divButtons {
  margin-top: 1rem;
  text-align: center; }

.divButton {
  display: flex; }

.hide {
  display: none !important; }

.flex {
  display: flex; }

.buttonDisable {
  border-radius: 10px !important;
  height: 50px;
  flex: 1;
  font-size: 16px !important;
  text-transform: inherit !important;
  color: #FFF !important; }

.buttonBack {
  border-radius: 3px !important;
  height: 13vw;
  flex: 1;
  font-size: 4vw !important;
  text-transform: inherit !important;
  color: #4A4A4A !important;
  text-transform: none;
  font-weight: 500 !important; }

.buttonNext {
  border-radius: 3px !important;
  border-color: #707070;
  height: 13vw;
  flex: 1;
  font-size: 4vw !important;
  font-weight: 500 !important;
  text-transform: inherit !important;
  color: #FFF !important; }

.margin-0 {
  margin: 0; }

.margin-top-10 {
  margin-top: 10px !important; }

.margin-top-15 {
  margin-top: 15px !important; }

.margin-top-20 {
  margin-top: 20px !important; }

.margin-top-30 {
  margin-top: 20px !important; }

.flex-1 {
  flex: 1 !important; }

.flex-9 {
  flex: 0.9; }

.flex-8 {
  flex: 0.8; }

.flex-7 {
  flex: 0.7; }

.flex-6 {
  flex: 0.6; }

.flex-5 {
  flex: 0.5; }

.flex-4 {
  flex: 0.4; }

.flex-3 {
  flex: 0.3; }

.flex-2 {
  flex: 0.2; }

.flex-01 {
  flex: 0.1 !important; }

.text-right {
  text-align: right !important; }

.text-left {
  text-align: left !important; }

/*
    Layout mobile
*/
.ul-padding-20 {
  padding-inline-start: 20px; }

.ul-margin-0 {
  margin: 0; }

.text-capitalize {
  text-transform: capitalize; }

.text-lowercase {
  text-transform: lowercase; }

.button-disabled {
  border-radius: 3px !important;
  border-color: #707070;
  height: 13vw;
  flex: 1;
  font-size: 4vw !important;
  text-transform: inherit !important;
  background-color: #E0E0E0 !important;
  font-size: 4vw !important;
  font-weight: 500 !important;
  color: #4a4a4a !important; }

.button-next {
  border-radius: 3px !important;
  border-color: #707070;
  height: 13vw;
  flex: 1;
  font-size: 4vw !important;
  font-weight: 500 !important;
  text-transform: inherit !important;
  color: #FFF !important; }

.button-back {
  border-radius: 3px !important;
  height: 13vw;
  flex: 1;
  font-size: 4vw !important;
  font-weight: 500 !important;
  text-transform: inherit !important;
  color: #4A4A4A !important;
  text-transform: none; }

.margin-top-vh1 {
  margin-top: 1vh !important; }

.margin-top-vh2 {
  margin-top: 2vh !important; }

.margin-top-vh3 {
  margin-top: 3vh !important; }

.margin-top-vh4 {
  margin-top: 4vh !important; }

.margin-top-vh5 {
  margin-top: 5vh !important; }

.margin-top-vh7 {
  margin-top: 7vh !important; }

.margin-top-vh8 {
  margin-top: 8vh !important; }

.margin-top-vh9 {
  margin-top: 9vh !important; }

.margin-top-vh10 {
  margin-top: 10vh !important; }

.margin-top-vh13 {
  margin-top: 13vh !important; }

.margin-top-vh15 {
  margin-top: 15vh !important; }

.margin-top-vh17 {
  margin-top: 17vh !important; }

.margin-top-vh20 {
  margin-top: 20vh !important; }

.margin-top-vh25 {
  margin-top: 25vh !important; }

.margin-top-vh30 {
  margin-top: 30vh !important; }

.margin-right-vw2 {
  margin-right: 2vw !important; }

.margin-right-vw5 {
  margin-right: 5vw !important; }

.margin-left-vw1 {
  margin-left: 1vw !important; }

.margin-left-vw2 {
  margin-left: 2vw !important; }

.margin-left-vw3 {
  margin-left: 3vw !important; }

.margin-left-vw5 {
  margin-left: 5vw !important; }

.margin-left-vw10 {
  margin-left: 10vw !important; }

.Logo {
  margin: 0;
  margin-top: 2vh;
  width: 32vw;
  object-fit: contain; }

.title {
  font-weight: 900 !important;
  margin-top: 4vh !important;
  font-size: 9vw !important;
  color: black;
  line-height: 7vh !important;
  letter-spacing: normal !important; }

.title-wizard {
  font-weight: bold !important;
  margin-top: 2vh !important;
  font-size: 8vw !important;
  line-height: 7vh !important;
  letter-spacing: normal !important; }

.sub-title {
  font-size: 5vw !important;
  font-weight: 500 !important;
  color: #4a4a4a; }

.text-description {
  font-size: 4vw !important;
  font-weight: 500 !important;
  color: #000; }

.text-info {
  font-size: 4.5vw !important;
  font-weight: 500 !important;
  color: #4A4A4A; }

.div-field {
  position: relative;
  margin-top: 2vh; }

.margin-3 {
  margin: 3vw; }

.div-flex {
  display: flex; }

.div-padding-left-2 {
  padding-left: 2vw; }

.div-padding-left-5 {
  padding-left: 5vw; }

.div-95 {
  width: 95vw; }

.div-90 {
  width: 90vw; }

.div-85 {
  width: 85vw; }

.div-80 {
  width: 80vw; }

.div-75 {
  width: 75vw; }

.div-70 {
  width: 70vw; }

.div-65 {
  width: 65vw; }

.div-62 {
  width: 62vw; }

.div-60 {
  width: 60vw; }

.div-58 {
  width: 58vw; }

.div-40 {
  width: 40vw; }

.div-35 {
  width: 35vw; }

.div-30 {
  width: 30vw; }

.div-25 {
  width: 25vw; }

.div-20 {
  width: 20vw; }

.div-faq {
  margin-right: 2vw; }

.div-bottom-2 {
  position: fixed;
  bottom: 2vh; }

.div-bottom-5 {
  position: fixed;
  bottom: 5vh;
  width: 90vw; }

.text-logo {
  color: #4a4a4a;
  font-size: 4vw; }

.subTitleCenter {
  color: #9B9B9B; }

.div-right {
  text-align: right; }

.div-left {
  text-align: left; }

.div-center {
  text-align: center;
  margin-top: 2rem; }

.div-center-no-margin {
  text-align: center; }

.img-bem-vindo {
  margin: 0;
  width: 60vw;
  height: 30vh; }

.img-home {
  margin: 0;
  width: 60vw;
  height: 30vh; }

.link-status {
  cursor: pointer !important;
  font-size: 4vw !important;
  font-weight: 500 !important;
  color: #4a4a4a !important; }

.input-helper {
  margin: 0;
  font-size: 0.75rem;
  margin-top: 8px;
  min-height: 1em;
  text-align: left;
  font-weight: 400;
  line-height: 1em;
  letter-spacing: 0.03333em; }

.text-underline {
  text-decoration: underline; }

/*
    Fim layout mobile
*/
/*
    Layout tablet
*/
@media (max-width: 1400px) and (min-width: 768px) {
  .box {
    margin: 3vw; }
  .text-description {
    font-size: 2vw !important; }
  .label {
    font-size: 2vw !important; }
  .div-field {
    margin-top: 4vh !important; }
  .link {
    font-size: 2vw !important; }
  .title-wizard {
    font-size: 4vw !important;
    line-height: 10vh !important; }
  .link-cep {
    font-size: 2vw !important; }
  .link-pep {
    font-size: 1.5vw !important; }
  .link-faq {
    font-size: 1.5vw !important; }
  .MuiSvgIcon-root {
    font-size: 2rem !important; }
  .font-termo {
    font-size: 2vw !important; }
  .button-disabled {
    height: 6vw;
    font-size: 1.5vw !important; }
  .button-next {
    height: 6vw;
    font-size: 1.5vw !important; }
  .button-back {
    height: 6vw;
    font-size: 1.5vw !important; }
  .link-status {
    font-size: 1.5vw !important; } }

@media (max-width: 1400px) and (min-width: 768px) and (orientation: landscape) {
  .Logo {
    margin-top: 0;
    width: 16vw; }
  .text-logo {
    color: #4a4a4a;
    font-size: 2vw; }
  .title {
    font-size: 5vw !important; }
  .sub-title {
    font-size: 2vw !important; }
  .img-bem-vindo {
    width: 30vw;
    height: 38vh; }
  .img-home {
    width: 35vw;
    height: 43vh; } }

@media (min-width: 900px) {
  .box {
    height: 100vh; }
  .MuiSvgIcon-root {
    font-size: 1.3rem !important; }
  .box-onboarding {
    background-size: 50% 100%;
    text-align: left;
    background-image: url("/images/background_desktop.png");
    background-repeat: no-repeat;
    margin: 0;
    padding: 1.5vw;
    height: 100%;
    width: 100%;
    position: fixed;
    overflow-x: hidden;
    display: flex; }
  .box-left {
    margin: 0 !important;
    flex: 0.5;
    float: left; }
  .box-right {
    margin: 0 !important;
    flex: 0.5;
    float: right; }
  .title {
    font-weight: 900 !important;
    font-size: 3.5vw !important;
    color: black;
    letter-spacing: normal !important; }
  .Logo {
    margin: 0;
    margin-top: 3vh;
    margin-right: 20vw;
    max-width: 15vw;
    max-height: 15vh;
    object-fit: contain; }
  .text-logo {
    font-size: 1.5vw; }
  .link-status {
    cursor: pointer !important;
    font-size: 1vw !important;
    font-weight: 500 !important;
    color: #4a4a4a !important; }
  .sub-title {
    font-size: 1.3vw !important;
    font-weight: 500 !important;
    color: #4a4a4a; }
  .img-bem-vindo {
    width: 20vw;
    height: 30vh; }
  .buttons-bottom {
    position: fixed;
    bottom: 45vh;
    width: 50%; }
  .buttonBack {
    border-radius: 3px !important;
    height: 5vh;
    font-size: 1vw !important; }
  .buttonNext {
    border-radius: 3px !important;
    height: 5vh;
    width: 20vw;
    font-size: 1vw !important; }
  .button-disabled {
    border-radius: 3px !important;
    height: 5vh;
    width: 20vw;
    font-size: 1vw !important; }
  .button-next {
    border-radius: 3px !important;
    height: 5vh;
    width: 20vw;
    font-size: 1vw !important; }
  .button-back {
    border-radius: 3px !important;
    height: 5vh;
    font-size: 1vw !important; }
  .font-termo {
    font-size: 0.7vw !important; }
  .sub-title-wizard {
    font-size: 1.3vw !important;
    text-align: left; }
  .div-field {
    width: 35vw; }
  .label {
    font-size: 1vw !important; }
  .link {
    font-size: 1vw !important; }
  .title-wizard {
    margin-top: 2vh !important;
    font-size: 2vw !important;
    line-height: 3vh !important; }
  .divContent {
    width: 35vw !important;
    margin-left: 13% !important; }
  .MuiButton-fullWidth {
    width: 50% !important; }
  .link-pep {
    font-size: 1vw !important; }
  .link-faq {
    font-size: 1.5vw !important; }
  .img-house {
    width: 25vw;
    height: 42vh; }
  .img-pendente {
    width: 10vw;
    height: 18vh; }
  .link-cep {
    font-size: 1vw !important; }
  .text-info {
    font-size: 1.5vw !important;
    color: #4A4A4A; }
  .div-faq {
    margin-right: 12vw; } }

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100%;
  overflow-x: hidden; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

#root {
  width: 100vw;
  height: 100vh; }
