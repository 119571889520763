@media (min-width: 900px) {
  .checkbox-termo {
    padding: 0 !important; }
  .img-doggie {
    width: 10vw;
    padding-left: 5vw;
    padding-top: 5vh; }
  .div-dropbox {
    margin-left: 18vw; }
  .title-dados-iniciais {
    font-size: 3vw !important; } }
