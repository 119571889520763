.title-fim {
  font-size: 7vw !important;
  font-weight: 900 !important; }

.text-fim {
  color: #4a4a4a;
  font-size: 5vw !important; }

.logo-fim {
  width: 50vw; }

.img-fim {
  width: 70vw;
  height: 30vh; }

.img-home-center {
  width: 70vw;
  height: 30vh; }

@media (min-width: 900px) {
  .title-fim {
    font-size: 3vw !important;
    font-weight: 900 !important; }
  .text-fim {
    color: #4a4a4a;
    font-size: 1.5vw !important; }
  .logo-fim {
    width: 15vw; }
  .img-fim {
    width: 25vw;
    height: 35vh; } }
