:export {
  min-width-tablet: 768px;
  max-width-tablet: 1400px; }

.img-token {
  width: auto;
  height: auto; }

@media (max-width: 1400px) and (min-width: 768px) and (orientation: landscape) {
  .img-token {
    width: 15vw !important; } }

@media (min-width: 900px) {
  .img-token {
    width: 20vw !important;
    height: 25vh !important; } }
