:export {
  min-width-tablet: 768px;
  max-width-tablet: 1400px; }

.description-info {
  font-weight: normal;
  font-size: 4vw;
  color: #001a71; }

.nativeSelect {
  padding: 0;
  position: relative;
  width: 100%;
  margin-top: 15; }

@media (max-width: 1400px) and (min-width: 768px) and (orientation: landscape) {
  .description-info {
    font-size: 2vw; } }

@media (min-width: 900px) {
  .description-info {
    font-size: 1vw; } }
