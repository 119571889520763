:export {
  min-width-tablet: 768px;
  max-width-tablet: 1400px; }

@media (min-width: 900px) {
  .text-situacao {
    font-size: 2vw !important;
    font-weight: 600 !important;
    color: #4A4A4A; }
  .img-center-aprovada {
    width: 40vw !important;
    height: 45vh !important; }
  .img-center-pendente {
    width: 20vw !important;
    height: 25vh !important; }
  .img-center-reprovada {
    width: 35vw !important;
    height: 45vh !important; }
  .img-center-processo {
    width: 30vw !important;
    height: 40vh !important; }
  .img-center {
    width: 40vw !important;
    height: 30vh !important; }
  .img-consulta-status {
    width: 25vw !important;
    height: 35vh !important; }
  .div-processo {
    margin-top: 10vh; }
  .div-aprovada {
    margin-top: 10vh; }
  .div-reprovada {
    margin-top: 10vh; } }
