.img-consulta-cep {
  margin: 0;
  width: 60vw;
  height: 30vh; }

@media (min-width: 900px) {
  .dialog-fullscreen-consulta-cep > .MuiDialog-container > .MuiDialog-paperFullScreen {
    width: 40vw !important;
    height: 85% !important;
    overflow-x: hidden; }
  .img-consulta-cep {
    width: 10vw;
    height: auto; }
  .icon-home-green {
    background-color: #439b11 !important; }
  .div-cep {
    margin-left: 0 !important; } }
