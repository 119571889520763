:export {
  min-width-tablet: 768px;
  max-width-tablet: 1400px; }

.img-dados-iniciais {
  width: auto;
  height: auto; }

@media (max-width: 1400px) and (min-width: 768px) and (orientation: landscape) {
  .img-dados-iniciais {
    width: 15vw !important; } }

@media (min-width: 900px) {
  .img-dados-iniciais {
    width: 10vw !important;
    height: 22vh !important;
    margin-right: 5vw; } }
