.header-stepper {
  box-shadow: 0 0.5px 3px 0 rgba(0, 0, 0, 0.16); }

.MuiStepper-root {
  padding: 0 !important;
  padding-top: 4vh !important;
  padding-bottom: 2vh !important; }

.MuiStepConnector-alternativeLabel {
  top: 2.5vh !important;
  left: calc(-50% + 7vw) !important;
  right: calc(50% + 7vw) !important; }

.icon-card {
  margin-top: 1vh !important; }

.icon-header {
  width: 13vw;
  fill: #f3f3f3; }

.icon-header-active {
  fill: #001a71 !important; }

.icon-header-completed {
  fill: #439b11 !important; }

.MuiStepContent-root {
  margin-top: 0 !important;
  border-left: 0 !important;
  margin-left: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important; }

.MuiStepLabel-label.MuiStepLabel-active {
  color: #001a71 !important; }

.MuiStepLabel-label.MuiStepLabel-completed {
  color: #439b11 !important; }

.MuiStepLabel-label {
  font-size: 3vw !important;
  font-weight: 500 !important;
  color: #4a4a4a !important;
  fill: #4a4a4a !important;
  text-align: center !important; }

.MuiStepConnector-line {
  margin-top: 1vh; }

@media (min-width: 900px) {
  .MuiStepLabel-label {
    font-size: 1vw !important; }
  .icon-header {
    width: 5vw;
    fill: #f3f3f3; }
  .MuiStepConnector-alternativeLabel {
    top: 1vh !important;
    left: calc(-50% + 3vw) !important;
    right: calc(50% + 3vw) !important; }
  .header-stepper {
    box-shadow: none; } }
