:export {
  min-width-tablet: 768px;
  max-width-tablet: 1400px; }

.card-documento {
  width: 35vw !important;
  height: 22vh !important;
  margin-right: 2vw;
  cursor: pointer; }

.div-center-documento {
  text-align: center;
  cursor: pointer; }

.cursor-not-allowed {
  cursor: not-allowed !important; }

.title-icon-documento {
  font-size: 5vw !important;
  margin-top: 2vh !important;
  font-weight: 500 !important; }

.icon-documento {
  height: 7vh !important;
  width: 20vw !important; }

.icon-help-doc {
  height: 7vh !important;
  width: 20vw !important; }

.text-help-doc {
  margin-top: 2vh !important;
  font-size: 3.5vw !important;
  font-weight: 400 !important;
  color: #4a4a4a; }

.card-foto-documento {
  height: 18vh !important;
  margin-right: 2.5vw;
  margin-left: 2.5vw; }

.check-icon-documento {
  height: 7vh !important;
  width: 20vw !important; }

.div-opacity-doc {
  opacity: 0.6;
  cursor: not-allowed !important; }

.delete-icon-image {
  cursor: pointer !important;
  float: right !important;
  font-size: 7vw !important; }

.text-reenvio-doc {
  font-size: 4.5vw !important; }

.success {
  fill: #439B11 !important;
  color: #439B11 !important; }

.link-upload {
  font-size: 5vw !important; }

.icon-margin-left {
  margin-left: 1vw; }

.alert-webcam {
  margin-top: 3vh; }

.st0 {
  fill: none;
  stroke: #000000;
  stroke-width: 0.684;
  stroke-linecap: round;
  stroke-linejoin: round; }

.size-icon-selfie {
  width: 10%;
  padding-left: 10%;
  padding-right: 10%; }

.div-center-selfie {
  text-align: center;
  padding-top: 1.5%; }

.icones-selfie {
  width: 2.5vw;
  min-width: 30px;
  max-width: 40px; }

.size-icon-glass {
  width: 13%;
  padding-left: 10%;
  padding-right: 10%; }

.silhueta-moldura {
  height: 45%;
  width: auto;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  position: absolute; }

@media (max-width: 1400px) and (min-width: 768px) and (orientation: landscape) {
  .card-documento {
    height: 23vh !important; }
  .card-foto-documento {
    height: 23vh !important; }
  .delete-icon-image {
    font-size: 5vw !important; }
  .text-reenvio-doc {
    font-size: 3.5vw !important; } }

@media (max-width: 768px) {
  .button-next {
    width: 100% !important; }
  .box-selfie-information h5 {
    font-size: 6vw; }
  .box-selfie-information h6 {
    font-size: 4vw; } }

@media (max-width: 1400px) and (min-width: 768px) {
  .button-next {
    width: 75% !important; } }

@media (min-width: 900px) {
  .box-selfie-information {
    margin-top: 1vw; }
  .box-selfie-information h5 {
    font-size: 2vw; }
  .box-selfie-information h6 {
    font-size: 1.2vw; }
  .box-selfie-information .divButtons {
    height: 100%;
    display: flex;
    align-content: flex-end; }
  .dialog-fullscreen-documento > .MuiDialog-container > .MuiDialog-paperFullScreen {
    width: 40vw !important;
    height: 85% !important;
    overflow-y: auto;
    overflow-x: hidden; }
  .silhueta-moldura {
    height: 60%;
    width: 38%;
    top: 45%;
    left: 50%; }
  .divContent {
    width: 30vw !important;
    margin-left: 3vw !important; }
  .divContent-biometria {
    width: 30vw !important;
    margin-left: 10vw !important; }
  .card-documento {
    width: 11vw !important;
    height: 15vh !important;
    margin-right: 2vw;
    margin-bottom: 0; }
  .card-foto-documento {
    width: 10vw !important;
    height: 15vh !important;
    margin-right: 2vw;
    margin-bottom: 0; }
  .title-icon-documento {
    font-size: 1vw !important; }
  .icon-documento {
    height: 5vh !important;
    width: 5vw !important; }
  .text-help-doc {
    margin-top: 2vh !important;
    font-size: 1vw !important; }
  .text-reenvio-doc {
    font-size: 1vw !important; }
  .delete-icon-image {
    font-size: 1.5vw !important; }
  .link-upload {
    font-size: 1.5vw !important; }
  .icon-help-doc {
    height: 7vh !important;
    width: 3vw !important; }
  .icon-margin-left {
    margin-left: 0.3vw; }
  #canvas {
    width: 85vw;
    height: 55vh; }
  .div-video {
    max-width: 100%; }
  .selfie_webcam {
    width: 100%;
    max-width: 100%; }
  .div-opacity-doc {
    opacity: 0.4; } }
