:export {
  min-width-tablet: 768px;
  max-width-tablet: 1400px; }

.icone_camera {
  width: 45px;
  height: 42px; }

.texto-dialog-camera {
  color: #000 !important; }

.dialog-content {
  margin-top: 10px;
  margin-bottom: 10px; }

.dialog-camera-title {
  margin-left: 5px !important;
  margin-top: 5px !important; }
