:export {
  min-width-tablet: 768px;
  max-width-tablet: 1400px; }

.link-internet-banking {
  color: #214BFF;
  font-size: 6vw;
  font-weight: 500; }

@media (min-width: 900px) {
  .link-internet-banking {
    font-size: 2vw !important; }
  .img-cliente-existente {
    width: 30vw;
    height: 40vh; } }
