:export {
  min-width-tablet: 768px;
  max-width-tablet: 1400px; }

.img-fim-desktop {
  width: 45vw;
  height: 65vh; }

.title-not-found-desktop {
  font-weight: 900 !important;
  font-size: 4vw !important;
  color: black;
  line-height: 7vh !important;
  letter-spacing: normal !important; }

.sub-title-not-found-desktop {
  font-size: 3vw !important;
  font-weight: 600 !important;
  color: #000; }

.text-description-not-found-desktop {
  font-size: 2vw !important;
  font-weight: 500 !important;
  color: #000; }

.img-404-superior-desktop {
  width: 15vw;
  height: 15vh;
  margin-top: 3vh;
  margin-right: 2vw;
  float: right; }

.box-not-found {
  text-align: left;
  width: 100vw;
  height: 100vh;
  background-size: 100% 100%;
  background-color: #F1F1F1;
  position: fixed;
  overflow: hidden; }
