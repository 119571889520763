:export {
  min-width-tablet: 768px;
  max-width-tablet: 1400px; }

.sub-title-not-found {
  font-size: 6vw !important;
  font-weight: 600 !important;
  color: #000; }

.text-description-not-found {
  font-size: 5vw !important;
  font-weight: 500 !important;
  color: #000; }

.img-404-superior {
  width: 45vw; }

.title-not-found {
  font-weight: 900 !important;
  font-size: 10vw !important;
  color: black;
  line-height: 7vh !important;
  letter-spacing: normal !important; }

.img-404-center {
  width: 75vw; }

.micro-logo-inferior {
  width: 30vw; }
