:export {
  min-width-tablet: 768px;
  max-width-tablet: 1400px; }

.closeIcon {
  float: right !important;
  font-size: 10vw !important;
  color: #BDBDBD; }

.title-faq {
  font-size: 4vw !important;
  font-weight: 600 !important;
  color: #4a4a4a; }

.title-page-faq {
  font-size: 6vw !important;
  font-weight: 900 !important;
  color: #000; }

.text-faq {
  font-size: 3.5vw !important;
  line-height: 1.67 !important; }

.logo-faq {
  margin: 0;
  margin-right: 20vw;
  width: 45vw;
  object-fit: contain; }

.expansion-panel-faq {
  background-color: #ebedf2 !important;
  word-break: break-word; }

.expansion-panel-detail {
  background-color: #FFF !important; }

.div-title-faq {
  margin-top: 6vh; }

.circular-progress-faq {
  width: 40vw !important;
  height: 40vw !important;
  margin-top: 15vh !important; }

.MuiExpansionPanelDetails-root {
  display: block !important; }

@media (max-width: 1400px) and (min-width: 768px) {
  .closeIcon {
    font-size: 7vw !important; }
  .title-faq {
    font-size: 4vw !important; }
  .title-page-faq {
    font-size: 5vw !important; }
  .text-faq {
    font-size: 2.5vw !important; } }

@media (min-width: 900px) {
  .dialog-fullscreen-termo > .MuiDialog-container > .MuiDialog-paperFullScreen {
    width: 60vw !important;
    height: 90% !important;
    max-width: 1280px !important;
    overflow-x: hidden;
    overflow-y: auto; }
  .logo-faq {
    width: 10vw; }
  .title-page-faq {
    font-size: 2.5vw !important; }
  .title-faq {
    font-size: 1.5vw !important; }
  .text-faq {
    font-size: 1vw !important;
    width: 60vw; }
  .closeIcon {
    font-size: 3vw !important; }
  .div-expansion-panel {
    width: 100%;
    margin-bottom: 10vh; }
  .div-title-faq {
    margin-top: 3vh; }
  .circular-progress-faq {
    width: 10vw !important;
    height: 10vw !important;
    margin-top: 10vh !important; } }
