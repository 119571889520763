@media (min-width: 900px) {
  .img-etapa-1 {
    margin: 0;
    width: 15vw !important;
    height: 30vh; }
  .img-etapa-2 {
    margin: 0;
    width: 20vw !important;
    height: 30vh; }
  .img-etapa-3 {
    margin: 0;
    width: 20vw !important;
    height: 30vh; }
  .text-onboarding {
    font-size: 1vw !important; }
  .title-onboarding {
    font-weight: 900 !important;
    font-size: 2.5vw !important;
    color: black;
    letter-spacing: normal !important; }
  .box-onboarding {
    padding: 1vw; }
  .box-etapa {
    margin-left: 3.3vw; }
  .logo-onboarding {
    margin: 0;
    width: 15vw;
    text-align: left; } }
